"use client";
import React, { useEffect, useRef, useState } from 'react';
import dynamic from "next/dynamic";
import { GoArrowLeft, GoArrowRight } from 'react-icons/go';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Useapi } from '@/helpers/apiContext'
import { useRouter } from 'next/navigation';
const ClientsCount = dynamic(() => import("@/components/CommonComponent/ClientsCount"), { ssr: false });
import Image from 'next/image';
import { motion } from 'framer-motion';
import toast from 'react-hot-toast';

const Banner = ({ props }: any) => {
  const router = useRouter()
  const [bannerData, setBannerData] = useState<any>();
  const { toggleDropdown } = Useapi();
  const [data, setData] = useState({ first: '', second: '' });
  const sliderRef = useRef<Slider>(null); // Ref for the slider
  const [ip, setip] = useState<any>()
  const Router = useRouter()
  const { basic_details } = Useapi()
//   useEffect(() => {
//     const message = localStorage.getItem('message');
//     if (message) { // Check if message is not null
//         toast.success(message); // Show success toast
//         localStorage.removeItem('message'); // Clear the message after showing it
//     }
// }, []);
  useEffect(() => {
    if (props) {
      setBannerData(props);
    }
  }, [props]);

  const splitStringByLastWords = (text: any, numOfWords: number) => {
    const words = text.split(' ');
    if (numOfWords >= words.length) {
      return { first: '', second: text };
    }
    const splitIndex = words.length - numOfWords;
    const firstPart = words.slice(0, splitIndex).join(' ');
    const secondPart = words.slice(splitIndex).join(' ');
    return { first: firstPart, second: secondPart };
  };
  useEffect(() => {
    const result = splitStringByLastWords('Recognized Excellence in Digital Marketing!', 3);
    setData(result);
  }, []);
  const settings = {
    infinite: true,
    arrows: false,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    // Allow keyboard focus on visible slides
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 1580,
        settings: { slidesToShow: 3 },
      },
    ],
  };

  return (
    <div className="w-full relative bg-blue banner min-h-[78vh]" onClick={() => {
      toggleDropdown('')
    }}>
      {
        !props &&
        <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url('/images/home-video-poster.webp')` }}></div>
      }
      {
        props &&
        <video
          autoPlay
          loop
          muted
          className='h-[100vh] min-h-[700px] lg:h-screen hidden sm:block 2xl:h-[95vh] w-full object-cover  transition-opacity duration-500'
        >
          <source src="/video/home-banner.mp4" type="video/mp4" />
        </video>
      }
      {
        (
          <div className="w-full sm:absolute px-6 lg:px-2 h-full top-0 left-0 flex justify-center bg-[#1090D059] items-center min-h-[78vh]">
            <div className="flex flex-col justify-around  text-white  py-10 lg:py-3 2xl:min-h-[60vh] items-start xl:w-[95%]  2xl:w-[80%] mx-auto">
              <div className="2xl:w-[80%]  ">
                <div
                  className="font-bold !text-white text-center sm:text-left text-[28px] lg:text-[32px] xl:text-[48px] 2xl:text-[62px] leading-tight xl:leading-[60px]"

                  dangerouslySetInnerHTML={{ __html: bannerData?.title || "<h1>Digital Marketing Company</h1>\r\n\r\n<h2>Transform Your Online Presence and Amplify Reach, Generate Leads, Drive Sales</h2>" }}
                />
                <p className="text-[22px] lg:text-[28px] text-center sm:text-left font-semibold mt-3">
                  {bannerData?.subtitle || "Get Results that Matters -"}
                </p>
                <p className="text-[16px] lg:text-[17px] text-center sm:text-left mt-2">
                  {bannerData?.description || 'Our comprehensive Digital Marketing Solutions are tailored to your unique needs. If you want to boost your website traffic, improve search engine rankings, boost online sales or generate better revenue, we have all the Premium tools and expertise to make it all happen.'}
                </p>
                <div className="flex mt-3 flex-wrap gap-4 justify-center md:justify-start items-center">
                  <button className="flex items-center h-[55px]  justify-center px-4 md:px-8 bg-white
                    text-pinkdarker font-medium hover:text-white text-[18px] rounded-md group hover:bg-pinkdarker transition duration-300">
                    <a href={`tel:${basic_details?.basic_details[0].phonenumber || ''} `}>
                      <span className="transition-transform duration-300 group-hover:-translate-x-2 text-[20px]">
                        Talk To an Expert
                      </span>
                    </a>
                    <GoArrowRight className="text-[20px] opacity-0 group-hover:opacity-100 transition duration-300 group-hover:translate-x-2" />
                  </button>
                  <div className='w-fit' onClick={() => Router.push('/get-a-free-quote')}>
                    <button className="flex items-center hover:bg-white bg-pinkdarker hover:text-pinkdarker font-medium justify-center px-4 xl:px-8
                        text-[18px] text-white rounded-md h-[55px] group  transition duration-300">
                      <span className="transition-transform duration-300 group-hover:-translate-x-2 text-[20px]">
                        Request a proposal!
                      </span>
                      <GoArrowRight className="text-[20px] opacity-0 group-hover:opacity-100 transition duration-300 group-hover:translate-x-2" />
                    </button>
                  </div>
                  <ClientsCount />
                </div>
              </div>

              <div className="mt-5 w-full max-w-[90vw] px-3 lg:px-1 xl:max-w-[70vw]">
                    <div className="flex justify-between">
                      <p className="text-[24px] lg:text-[28px] font-medium">
                        {bannerData?.our_core_services}
                      </p>
                      <div className="flex gap-2 text-[24px]">
                        <div
                          className="arrow left hover:text-pink cursor-pointer text-[24px]"
                          onClick={() => sliderRef.current?.slickPrev()} >
                          <GoArrowLeft />
                        </div>
                        <div
                          className="arrow right hover:text-pink cursor-pointer text-[24px]"
                          onClick={() => sliderRef.current?.slickNext()}
                        >
                          <GoArrowRight />
                        </div>
                      </div>
                    </div>
                    </div>


              <div className="mt-5 w-full max-w-[95vw] sm:max-w-[90vw] md:max-w-[85vw] lg:max-w-[80vw] xl:max-w-[80vw] 2xl:max-w-[76vw] px-4 sm:px-3 md:px-2 ">

                <Slider ref={sliderRef} {...settings} className="mt-5">
                  {bannerData?.card.map((service: any, index: any) => (
                    // <ServiceCard key={service.slug} service={service} />
                    <motion.div
                      key={service?.slug}
                      initial={{ opacity: 0, x:30 }}
                      animate={{ opacity: 1, x:0 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                      className="rounded-2xl border max-w-[297px] hover:bg-white h-[180px] hover:text-homeblack group p-5 flex flex-col justify-between mx-4"
                    // onClick={()=>router.push(service?.slug)}
                    >
                      <div className="flex justify-between">
                        <div className="text-[20px] font-medium group-hover:font-semibold">
                          {service.title}
                        </div>
                        {/* <span className="flex justify-center items-center min-w-[45px] h-[45px] text-[28px] rounded-full border border-white group-hover:bg-pink group-hover:text-white">
                                      <Image
                                        src={'/images/banner-arrow.svg'}
                                        alt="icon"
                                        loading='lazy'
                                        width={16}
                                        height={14}
                                        className="group-hover:rotate-45 transition duration-200"
                                      />
                                    </span> */}
                      </div>
                      <p className="mt-3 text-[16px] lg:text-[18px] group-hover:text-homegrey">{service.description}</p>
                    </motion.div>
                  ))}
                </Slider>

              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default Banner;












// "use client";
// import React, { useEffect, useRef, useState } from 'react';
// import dynamic from "next/dynamic";
// import { GoArrowLeft, GoArrowRight } from 'react-icons/go';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Useapi } from '@/helpers/apiContext'
// import { useRouter } from 'next/navigation';
// const ClientsCount = dynamic(() => import("@/components/CommonComponent/ClientsCount"), { ssr: false });
// import Image from 'next/image';
// import { motion } from 'framer-motion';
// import toast from 'react-hot-toast';
// import DynamicPoints from '@/Data/RandomPoints.json'

// const Banner = ({ props }: any) => {
//   const router = useRouter()
//   const [bannerData, setBannerData] = useState<any>();
//   const { toggleDropdown } = Useapi();
//   const [data, setData] = useState({ first: '', second: '' });
//   const sliderRef = useRef<Slider>(null); // Ref for the slider
//   const [ip, setip] = useState<any>()
//   const Router = useRouter()
//   const { basic_details } = Useapi()
//   //   useEffect(() => {
//   //     const message = localStorage.getItem('message');
//   //     if (message) { // Check if message is not null
//   //         toast.success(message); // Show success toast
//   //         localStorage.removeItem('message'); // Clear the message after showing it
//   //     }
//   // }, []);
//   const [randomPoints, setRandomPoints] = useState<any>();
//   useEffect(() => {
//     const shuffled = DynamicPoints.statistics.sort(() => 0.5 - Math.random());
//     setRandomPoints(shuffled.slice(0, 3));
//   }, []);
//   useEffect(() => {
//     if (props) {
//       setBannerData(props);
//     }
//   }, [props]);

//   const splitStringByLastWords = (text: any, numOfWords: number) => {
//     const words = text.split(' ');
//     if (numOfWords >= words.length) {
//       return { first: '', second: text };
//     }
//     const splitIndex = words.length - numOfWords;
//     const firstPart = words.slice(0, splitIndex).join(' ');
//     const secondPart = words.slice(splitIndex).join(' ');
//     return { first: firstPart, second: secondPart };
//   };
//   useEffect(() => {
//     const result = splitStringByLastWords('Recognized Excellence in Digital Marketing!', 3);
//     setData(result);
//   }, []);
//   const settings = {
//     infinite: true,
//     arrows: false,
//     speed: 200,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     autoplay: true,
//     // Allow keyboard focus on visible slides
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: { slidesToShow: 1 },
//       },
//       {
//         breakpoint: 1024,
//         settings: { slidesToShow: 2 },
//       },
//       {
//         breakpoint: 1580,
//         settings: { slidesToShow: 3 },
//       },
//     ],
//   };

//   return (
//     <div className="w-full relative bg-blue banner min-h-[78vh]" onClick={() => {
//       toggleDropdown('')
//     }}>
//       {
//         !props &&
//         <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url('/images/home-video-poster.webp')` }}></div>
//       }
//       {
//         props &&
//         <video
//           autoPlay
//           loop
//           muted
//           className='h-[100vh] min-h-[700px] lg:h-screen hidden sm:block 2xl:h-[95vh] w-full object-cover  transition-opacity duration-500'
//         >
//           <source src="/video/home-banner.mp4" type="video/mp4" />
//         </video>
//       }
//       {
//         (
//           <div className="w-full sm:absolute px-6 lg:px-2 h-full top-0 left-0 flex justify-center bg-[#1090D059] items-center min-h-[78vh]">
//             <div className="flex  justify-between   text-white  py-10 lg:py-3 2xl:min-h-[60vh] items-start xl:w-[95%]  2xl:w-[80%] mx-auto">
//               <div>

//                 <div className="2xl:w-[80%]  ">
//                   <div
//                     className="font-bold !text-white text-center sm:text-left text-[28px] lg:text-[32px] xl:text-[48px] 2xl:text-[62px] leading-tight xl:leading-[60px]"

//                     dangerouslySetInnerHTML={{ __html: bannerData?.title || "<h1>Digital Marketing Company</h1>\r\n\r\n<h2>Transform Your Online Presence and Amplify Reach, Generate Leads, Drive Sales</h2>" }}
//                   />
//                   <p className="text-[22px] lg:text-[28px] text-center sm:text-left font-semibold mt-3">
//                     {bannerData?.subtitle || "Get Results that Matters -"}
//                   </p>
//                   <p className="text-[16px] lg:text-[17px] text-center sm:text-left mt-2">
//                     {bannerData?.description || 'Our comprehensive Digital Marketing Solutions are tailored to your unique needs. If you want to boost your website traffic, improve search engine rankings, boost online sales or generate better revenue, we have all the Premium tools and expertise to make it all happen.'}
//                   </p>
//                   <div className="flex mt-3 flex-wrap gap-4 justify-center md:justify-start items-center">
//                     <button className="flex items-center h-[55px]  justify-center px-4 md:px-8 bg-white
//                     text-pinkdarker font-medium hover:text-white text-[18px] rounded-md group hover:bg-pinkdarker transition duration-300">
//                       <a href={`tel:${basic_details?.basic_details[0].phonenumber || ''} `}>
//                         <span className="transition-transform duration-300 group-hover:-translate-x-2 text-[20px]">
//                           Talk To an Expert
//                         </span>
//                       </a>
//                       <GoArrowRight className="text-[20px] opacity-0 group-hover:opacity-100 transition duration-300 group-hover:translate-x-2" />
//                     </button>
//                     <div className='w-fit' onClick={() => Router.push('/get-a-free-quote')}>
//                       <button className="flex items-center hover:bg-white bg-pinkdarker hover:text-pinkdarker font-medium justify-center px-4 xl:px-8
//                         text-[18px] text-white rounded-md h-[55px] group  transition duration-300">
//                         <span className="transition-transform duration-300 group-hover:-translate-x-2 text-[20px]">
//                           Request a proposal!
//                         </span>
//                         <GoArrowRight className="text-[20px] opacity-0 group-hover:opacity-100 transition duration-300 group-hover:translate-x-2" />
//                       </button>
//                     </div>
//                     <ClientsCount />
//                   </div>
//                 </div>

//                 <div className="mt-5  w-full">

//                   <Image
//                     src={'/Home_image/banner-img.png'}
//                     alt="icon"
//                     loading='lazy'
//                     width={679}
//                     height={278}
//                     className="group-hover:rotate-45   transition duration-200"
//                   />
//                 </div>

//               </div>

//               <div className="flex flex-1 flex-col w-full mt-6 gap-8 justify-center  sm:gap-10 flex-wrap">
//                 {randomPoints?.map((point: any, index: number) => (
//                   <div
//                     key={index}
//                     className="flip-card mt-10 sm:mt-0 min-w-[180px] size-[180px]  min-h-[180px]"
//                   >
//                     <div className="flip-card-inner ">
//                       <div>
//                         <Image src={'/case-study/tick_banner.svg'} alt={'w3era'} height={100} width={100} className="absolute z-50 -top-10 left-10" />
//                       </div>
//                       <div className="flip-card-front flex items-center justify-center p-2 text-center">
//                         <div className="title  text-[14px] "> <span className="text-[28px] text-blue">{point?.value}</span> <br />{point?.title}</div>
//                       </div>
//                       <div className="flip-card-back flex items-center justify-center p-2 text-center">
//                         <div className="title text-[14px] "> {point?.description}</div>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         )}
//     </div>
//   );
// };

// export default Banner;



// if anything needs back same slider take code before 4/8/2025====================================================================================